/**
 * wxid-微信id
 * initial-姓名首字母
 * headerUrl-头像地址
 * nickname-昵称
 * sex-性别 男1女0
 * remark-备注
 * signature-个性签名
 * telphone-电话号码
 * album-相册
 * area-地区
 * from-来源
 * desc-描述
 */
const contacts = [{ //昵称备注都有的朋友
        "wxid": "wxid_zhaohd",
        "initial": 'z',
        "headerUrl": "/images/header/header01.png",
        "nickname": "阿荡",
        "sex": 1,
        "remark": "阿荡",
        "signature": "填坑小能手",
        "telphone": 18896586152,
        "album": [{
            imgSrc: ""
        }],
        "area": ["中国", "北京", "海淀"],
        "from": "",
        "tag": "",
        "desc": {

        }
    },
    {
        "wxid": "wxid_baiqian",
        "initial": 'l',
        "headerUrl": "/images/header/baiqian.jpg",
        "nickname": "刘庆华",
        "sex": 0,
        "remark": "",
        "signature": "青丘女帝，天族天妃",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/baiqian/baiqian01.jpeg",
            date: 182625262
        }, {
            imgSrc: "/images/album/baiqian/baiqian02.jpeg",
            date: 182625262
        }],
        "area": ["青丘", "狐狸洞"],
        "from": "通过扫一扫",
        "tag": "女帝",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    }, { //昵称备注都有的朋友
        "wxid": "wxid_yehua",
        "initial": 'y',
        "headerUrl": "/images/header/yehua.jpg",
        "nickname": "陈桂堂",
        "sex": 1,
        "remark": "夜华",
        "signature": "浅浅，过来",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu02.jpeg",
            date: 182625262
        }, {
            imgSrc: "/images/album/baiqian/baiqian02.jpeg",
            date: 182625262
        }],
        "area": ["九重天", "洗梧宫"],
        "from": "通过扫一扫",
        "tag": "太子",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },
    {
        "wxid": "wxid_liubei",
        "initial": 'l',
        "headerUrl": "/images/header/t1.jpg",
        "nickname": "刘德兴",
        "sex": 1,
        "remark": "刘德兴",
        "signature": "惟贤惟德，仁服于人",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/header/t1.jpeg",
            date: 182625262
        }, {
            imgSrc: "/images/album/baiqian/baiqian01.jpeg",
            date: 182625262
        }],
        "area": ["蜀国", "荆州"],
        "from": "通过扫一扫",
        "tag": "主公",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },
    {
        "wxid": "wxid_guangyu",
        "initial": 's',
        "headerUrl": "/images/header/t2.jpg",
        "nickname": "姚贵",
        "sex": 1,
        "remark": "姚贵",
        "signature": "观尔乃插标卖首",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/baiqian/baiqian02.jpeg",
            date: 182625262
        }, {
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["蜀国", "荆州"],
        "from": "通过扫一扫",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },
    {
        "wxid": "wxid_zhugeliang",
        "initial": 'z',
        "headerUrl": "/images/header/t3.jpg",
        "nickname": "诸肠星",
        "sex": 1,
        "remark": "诸肠星",
        "signature": "你可识得此阵？",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/baiqian/baiqian01.jpeg",
            date: 182625262
        }, {
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["蜀国", "荆州"],
        "from": "通过扫一扫",
        "tag": "卧龙",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },
    {
        "wxid": "wxid_sunshangxiang",
        "initial": 's',
        "headerUrl": "/images/header/t4.jpg",
        "nickname": "孙玉",
        "sex": 0,
        "remark": "孙玉",
        "signature": "夫君,身体要紧~",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/baiqian/baiqian02.jpeg",
            date: 182625262
        }],
        "area": ["吴国", "富春"],
        "from": "通过手机号码添加",
        "tag": "孙夫人",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },
    {
        "wxid": "wxid_sunquan",
        "initial": 's',
        "headerUrl": "/images/header/h5.png",
        "nickname": "腾讯新闻",
        "sex": 1,
        "remark": "腾讯新闻",
        "signature": "从世界去看美丽中国",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["吴国", "吴郡"],
        "from": "通过手机号码添加",
        "tag": "主公",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },
    {
        "wxid": "wxid_huangyueying",
        "initial": 'h',
        "headerUrl": "/images/header/huangyueying.jpg",
        "nickname": "黄月英",
        "sex": 0,
        "remark": "黄月英",
        "signature": "哼哼~",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu02.jpeg",
            date: 182625262
        }],
        "area": ["蜀", "荆州"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    }, {
        "wxid": "wxid_zhenji1",
        "initial": 'x',
        "headerUrl": "/images/header/t6.jpg",
        "nickname": "微信支付",
        "sex": 0,
        "remark": "微信支付",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },
    {
        "wxid": "wxid_zhenji2",
        "initial": 'y',
        "headerUrl": "/images/header/h6.png",
        "nickname": "订阅号",
        "sex": 0,
        "remark": "订阅号",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },{
        "wxid": "wxid_zhenji3",
        "initial": 'l',
        "headerUrl": "/images/header/t9.jpg",
        "nickname": "刘肖",
        "sex": 0,
        "remark": "刘肖",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },{
        "wxid": "wxid_zhenji3",
        "initial": 'z',
        "headerUrl": "/images/header/t10.jpg",
        "nickname": "周凯",
        "sex": 0,
        "remark": "周凯",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },{
        "wxid": "wxid_zhenji4",
        "initial": 'z',
        "headerUrl": "/images/header/t11.jpg",
        "nickname": "周于洋",
        "sex": 0,
        "remark": "周于洋",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },{
        "wxid": "wxid_zhenji5",
        "initial": 'b',
        "headerUrl": "/images/header/h8.jpg",
        "nickname": "京东白条",
        "sex": 0,
        "remark": "京东白条",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },{
        "wxid": "wxid_zhenji6",
        "initial": 'c',
        "headerUrl": "/images/header/t13.jpg",
        "nickname": "陈格",
        "sex": 0,
        "remark": "陈格",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },{
        "wxid": "wxid_zhenji7",
        "initial": 'z',
        "headerUrl": "/images/header/t14.jpg",
        "nickname": "钟健非",
        "sex": 0,
        "remark": "钟健非",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },{
        "wxid": "wxid_zhenji8",
        "initial": 'h',
        "headerUrl": "/images/header/t15.jpg",
        "nickname": "胡晓非",
        "sex": 0,
        "remark": "胡晓非",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },{
        "wxid": "wxid_zhenji9",
        "initial": 'c',
        "headerUrl": "/images/header/t16.jpg",
        "nickname": "曹月莓",
        "sex": 0,
        "remark": "曹月莓",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },{
        "wxid": "wxid_zhenji10",
        "initial": 'c',
        "headerUrl": "/images/header/t17.jpg",
        "nickname": "曹学健",
        "sex": 0,
        "remark": "曹学健",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },{
        "wxid": "wxid_zhenji11",
        "initial": 'o',
        "headerUrl": "/images/header/t18.jpg",
        "nickname": "欧阳勇",
        "sex": 0,
        "remark": "欧阳勇",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    },{
        "wxid": "wxid_zhenji12",
        "initial": 'z',
        "headerUrl": "/images/header/t19.jpg",
        "nickname": "李😀非",
        "sex": 0,
        "remark": "李😀非",
        "signature": "仿佛兮若轻云之蔽月",
        "telphone": 18896586152,
        "album": [{
            imgSrc: "/images/album/guanyu/guanyu01.jpeg",
            date: 182625262
        }],
        "area": ["魏", "荆州", "中山"],
        "from": "通过手机号码添加",
        "tag": "蜀",
        "desc": {
            "title": "",
            "picUrl": ""
        }
    }
]

const contact = {
    contacts
}
contact.getUserInfo = function(wxid) {
    if (!wxid) {
        return;
    } else {
        for (var index in contacts) {
            if (contacts[index].wxid === wxid) {
                return contacts[index]
            }
        }
    }
}

export default contact